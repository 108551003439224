import React, { useState, useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { LOGO, SITE_NAME } from '@/utils'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    Drawer,
    SwipeableDrawer,
    ListItemText,
} from '@material-ui/core'
import AuthContext from '@/product/context/auth/authContext'
import CommonContext from '@/product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import CartContext from '@/product/context/cart/cartContext'
import AlertContext from '@/product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '@/product/common/components'
import './Header.css'
import AutopltContext from '@/product/context/autopilot/autopltContext'
import UserContext from '@/product/context/user/userContext'
import ProductContext from '@/product/context/product/productContext'
import BidHistory from '@/custom/components/organisms/BidHistory'
import LanguageSwitch from '../LanguageSwitch'
import BuynowContext from '@/product/context/buynow/buynowContext'
import PayContext from '@/product/context/payment/payContext'
import CreditContext from '@/product/context/credit/creditContext'
import OfflineContext from '@/product/context/offline/offlineContext'
import Loaders from '../Loaders'
import { Popover } from '@material-ui/core'
import { mapData } from '@/product/common/components'
import Login from '@/views/Login/login'
import Registration from '@/views/Registration'
import { useTranslation } from 'react-i18next'
import CustomDialog from '@/product/components/organisms/Dialog'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import * as Yup from 'yup'
import PhoneValidationComponent from '@/product/components/species/components/user/PhoneValidationComponent'
import publicIp from 'public-ip'
import ipLocation from 'iplocation'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import algoliasearch from 'algoliasearch'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import Autocomplete from '../../organisms/AutoComplete'
import AuctionContext from '@/product/context/seller/auction/auctionContext'

import Moment from 'moment'

const Header = () => {
    const APP_ID = process.env.REACT_APP_ALGOLIA_APPID
    const APP_KEY = process.env.REACT_APP_ALGOLIA_APIKEY
    const APP_INDEX = process.env.REACT_APP_ALGOLIA_INDEX
    const { t, i18n } = useTranslation()
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const buynowContext = useContext(BuynowContext)
    const matches = useMediaQuery('(max-width:500px)')
    const {
        all_auctions,
        all_lot_count,
        getAllAuctions,
        getAllLotCount,
        getAllMainLoatCount,
        responseStatus: responseStatusSeller,
    } = useContext(AuctionContext)

    const { setAlert } = alertContext
    const {
        user,
        isAuthenticated,
        loadPendingCount,
        isAdmin,
        cartCount,
        logout,
        switchUser,
        loadUser,
        login,
        responseStatus: responseStatusAuth,
        clearResponse: clearResponseAuth,
    } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })

    const formik = useFormik({
        initialValues: {
            user: '',
        },
        onSubmit: (values) => {},
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [category, setCategory] = useState([])
    const [brand, setBrand] = useState([])
    const [model, setModel] = useState([])
    const [doors, setDoors] = useState([])
    const [bodyType, setBodyType] = useState([])
    const [trans, setTrans] = useState([])
    const [fuel, setFuel] = useState([])
    const [savedSearchPopup, setSavedSearchPopup] = useState(false)
    const [phoneVerify, setPhoneVerify] = useState(false)
    const [initialValues1, setInitialValues1] = useState({ phone: '' })

    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext
    const {
        getAllSearchProducts,
        responseStatus,
        addSavedSearch,
        getAllActiveSorts,
        active_sorts,
        setSavedSearchModel,
        savedSearchModel,
        setPhonemodelPopup,
        getAllDashboardProducts,
        phonemodelPopup,
    } = productContext

    const { responseStatus: responseStatusPayment, clearResponse: clearResponsePayment } =
        payContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const {
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
        updateProfile,
    } = userContext
    const { responseStatus: responseStatusCredit, clearResponse: clearResponseCredit } =
        creditContext

    const { responseStatus: responseStatusOffline, clearResponse: clearResponseOffline } =
        offlineContext

    const { responseStatus: responseStatusAutoPlt, clearResponse: clearResponseAutoPlt } =
        autopltContext

    const {
        responseStatus: responseStatusBuyNow,
        clearResponse: clearResponseBuyNow,
        getAllCartProducts,
        search_allproducts,
    } = buynowContext

    const { searchValue, setSearchValue, isLoading, clearSearchValue } = commonContext

    const {
        setCurrentMembership,
        allBiddingPlan,
        membership,
        loginModal,
        signupModal,
        setSignupModal,
        setLoginModal,
        alldata_all_dropdown,
        switchLanguage,
        currentLanguage,
        languages,
        setProfilePopup,
    } = customCommonContext

    const [ipValues, setIpValues] = React.useState(null)
    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [unSoldLotCount, setUnsoldLotCount] = useState()
    const [search, setSearch] = useState({})
    const [validationArray1, setValidationArray1] = useState({})

    const searchClient = algoliasearch(APP_ID, APP_KEY)
    const changeEditDialogStatus = () => {
        setSavedSearchModel()
    }
    const closeFunction = () => {
        setSavedSearchModel(false)
    }

    const changeEditDialogPopup = () => {
        setSavedSearchPopup(false)
    }
    const closePopupFunction = () => {
        setSavedSearchPopup(false)
    }

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const validationArray = Yup.object({
        email: Yup.string().email(t('Invalid email format')).required(t('¡Requerido!')),
        search_text: Yup.string().required(t('Title is required feild')),
        // termCheck: Yup.bool().oneOf([true], t('Por favor acepte los términos para continuar.')),
    })

    const validationArrayWithOutEmail = Yup.object({
        search_text: Yup.string().required(t('Title is required feild')),
        // termCheck: Yup.bool().oneOf([true], t('Por favor acepte los términos para continuar.')),
    })

    const formikSelection = useFormik({
        initialValues: {
            id: 0,
            category: [],
            model: 0,
            brand: 0,
            search_text: '',
            power: '',
            doors: '',
            transmission: '',
            body_type: '',
            registration_year: '',
            wprice: '',
            kilometers: '',
            fuel: '',
            termCheck: false,
        },
        validationSchema: user && isAuthenticated ? validationArrayWithOutEmail : validationArray,
        onSubmit: (values) => {
            addSavedSearch(values)
            resetForm()
            setEditDialog(false)
        },
    })

    const submitHandler = (payload) => {
        updateProfile(payload, null, 'phoneNumberUodate')
    }
    const validationArrayPhoneNumber = Yup.object({
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, t('Only numbers are allowed'))
            .required(t('Required'))
            .min(8, t('Minimum 8 characters'))
            .max(15, t('Maximum 15 characters')),
    })

    const formikPhone = useFormik({
        initialValues: initialValues1,
        validationSchema: validationArrayPhoneNumber,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (
                values.phone_verified === 1 &&
                formikPhone.values.verified_phonenum === formikPhone.values.phone
            ) {
                submitHandler(values)
            } else {
                setPhoneVerify(true)
            }
        },
    })

    const phoneNumber = [
        {
            label: 'Teléfono',
            name: 'phone',
            type: 'phone',
            class: 'col-10',
            size: 'small',
            autoFocus: false,
            formik: formikPhone,
        },
    ]

    const searchBody = {
        formik: formikSelection,
        data: [
            {
                label: t('email_address'),
                name: 'email',
                type: 'email',
                placeholder: t('enter_your_email_address'),
                class: 'col-12',
                autoFocus: true,
            },
            {
                label: t('title'),
                type: 'text',
                placeholder: t('Enter the title'),
                class: 'col-12',
                name: 'search_text',
            },
            {
                label: t('Select Category'),
                type: 'multiselect',
                placeholder: t('Select Category'),
                class: 'col-12',
                options: category,
                name: 'category',
            },
            {
                label: t('Select Brand'),
                type: 'select',
                placeholder: t('Select Brand'),
                class: 'col-12',
                options: brand,
                name: 'brand',
            },
            {
                label: t('Select Model'),
                type: 'select',
                placeholder: t('Select the model'),
                class: 'col-12',
                options: model,

                name: 'model',
            },
            {
                label: t('year'),
                type: 'text',
                placeholder: t('Enter Year'),
                class: 'col-12',
                name: 'registration_year',
            },
            {
                label: t('Body Type'),
                type: 'select',
                placeholder: t('Select Body Type'),
                options: bodyType,
                class: 'col-12',
                name: 'body_type',
            },
            {
                label: t('Transmission'),
                type: 'select',
                placeholder: t('Select Transmission'),
                class: 'col-12',
                options: trans,
                name: 'transmission',
            },
            {
                label: t('doors'),
                type: 'select',
                placeholder: t('Select Doors'),
                options: doors,
                class: 'col-12',
                name: 'doors',
            },
            {
                label: t('fuel'),
                type: 'select',
                placeholder: t('Select Fuel'),
                options: fuel,
                class: 'col-12',
                name: 'fuel',
            },
            // {
            //     label: 'Acepto las condiciones de uso y la Información básica de Protección de Datos',
            //     type: 'checkbox',
            //     placeholder: 'termCheck',
            //     class: 'col-12',
            //     name: 'termCheck',
            // },
        ],
    }

    if (user && isAuthenticated) {
        searchBody.data = searchBody.data.filter((e) => e.name !== 'email')
    }

    useEffect(() => {
        async function fetchData() {
            const ipaddress = await publicIp.v4()
            const ipLoc = await ipLocation(ipaddress)
            if (ipLoc)
                setIpValues({
                    latitude: ipLoc.latitude,
                    longitude: ipLoc.longitude,
                    country: ipLoc.country.name,
                    from: 'ipaddress',
                })
        }
        fetchData()
    }, [])

    const handleSwitchLanguage = (data) => {
        switchLanguage(data)
        i18n.changeLanguage(data)
    }

    useEffect(() => {
        if (ipValues) {
            if (ipValues.country === 'Argentina') {
                handleSwitchLanguage('for_argentina')
            } else if (ipValues.country === 'Uruguay') {
                handleSwitchLanguage('for_uruguay')
            } else if (ipValues.country === 'United States') {
                handleSwitchLanguage('for_argentina')
            } else if (ipValues.country === 'United Kingdom') {
                handleSwitchLanguage('for_uruguay')
            }
        }
    }, [ipValues, languages])

    useEffect(() => {
        localStorage.setItem('currLanguage', currentLanguage)
    }, [currentLanguage, i18n.language])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'phoneNumberUodate') {
                if (responseStatusUser.status === 'success') {
                    setPhonemodelPopup(false)
                    loadUser()
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        if (formikSelection.values.brand && formikSelection.values.brand !== '') {
            let mapping = [...alldata_all_dropdown]
            let brand = []
            mapping
                .filter(
                    (item) =>
                        parseInt(formikSelection.values.brand, 10) === parseInt(item.level_id, 10),
                )
                .map((lot, index) => {
                    brand.push({ value: lot.id.toString(), show: lot.description })
                })
            setModel(brand)
        }
    }, [formikSelection.values.brand])

    useEffect(() => {
        if (alldata_all_dropdown.length) {
            let mapping = [...alldata_all_dropdown]
            let allCategory = []
            let allBrand = []
            let allBody = []
            let allDoors = []
            let allFuel = []
            let allTrans = []
            mapping.map((lot, index) => {
                if (lot.variable == 'category') {
                    allCategory.push({ value: lot.id.toString(), show: lot.description })
                } else if (lot.variable == 'brand') {
                    allBrand.push({ value: lot.id.toString(), show: lot.description })
                } else if (lot.variable == 'doors') {
                    allDoors.push({ value: lot.id.toString(), show: lot.description })
                } else if (lot.variable == 'fuel') {
                    allFuel.push({ value: lot.id.toString(), show: lot.description })
                } else if (lot.variable == 'transmission') {
                    allTrans.push({ value: lot.id.toString(), show: lot.description })
                } else if (lot.variable == 'body_type') {
                    allBody.push({ value: lot.id.toString(), show: lot.description })
                }
            })
            setCategory(allCategory)
            setBrand(allBrand)
            setTrans(allTrans)
            setBodyType(allBody)
            setFuel(allFuel)
            setDoors(allDoors)
        }
    }, [alldata_all_dropdown])

    useEffect(() => {
        if (searchValue) {
            if (searchValue?.filters?.searchbar?.value) {
                formikSelection.setFieldValue(
                    'search_text',
                    searchValue?.filters?.searchbar?.value
                        ? searchValue?.filters?.searchbar?.value
                        : '',
                )
            }
        }
    }, [searchValue])

    useEffect(() => {
        if (responseStatus) {
            console.log('responseStatus', responseStatus)
            if (responseStatus.from === 'saved_search_msg') {
                if (responseStatus.status === 'success') {
                    closeFunction()
                    if (!user) setSavedSearchPopup(true)
                }
            }
        }
    }, [responseStatus])

    // const switch_user = [
    //     {
    //         placeholder: 'Switch User',
    //         class: 'col-12 pl-0 switchUserDD',
    //         type: 'select',
    //         variant: 'standard',
    //         options: [
    //             { value: 'user1', show: 'User-Seller' },
    //             { value: 'user2', show: 'User-Buyer' },
    //         ],
    //         name: 'user',
    //         formik: formik,
    //     },
    // ]

    useEffect(() => {
        if (formik.values.user) {
            if (formik.values.user === 'user1') {
                login({
                    email: 'abdul.auctionsoftware@gmail.com',
                    password: '12345678',
                })
            } else if (formik.values.user === 'user2') {
                login({
                    email: 'sara@auctionsoftware.com',
                    password: '12345678',
                })
            }
        }
        if (!isAuthenticated) {
            formik.values.user = ''
        }
    }, [formik.values.user, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)
            if (user) {
                if (user.membership) {
                    if (allBiddingPlan && allBiddingPlan.length) {
                        let activePlan = allBiddingPlan.filter(
                            (value) => value.type === user.membership,
                        )
                        if (activePlan.length) {
                            setCurrentMembership(activePlan[0])
                        } else {
                            setCurrentMembership('')
                        }
                    }
                }
            }
        }
    }, [isAuthenticated, user, allBiddingPlan])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    // useEffect(() => {
    //     if (responseStatusUser) {
    //         setAlert(responseStatusUser.message, responseStatusUser.status)
    //         clearResponseUser()
    //     }
    //     if (responseStatusProduct) {
    //         setAlert(responseStatusProduct.message, responseStatusProduct.status)
    //         clearResponseProduct()
    //     }
    //     if (responseStatusAutoPlt) {
    //         setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
    //         clearResponseAutoPlt()
    //     }
    //     if (responseStatusAuth) {
    //         setAlert(responseStatusAuth.message, responseStatusAuth.status)
    //         clearResponseAutoPlt()
    //     }
    //     if (responseStatusBuyNow) {
    //         setAlert(responseStatusBuyNow.message, responseStatusBuyNow.status)
    //         clearResponseBuyNow()
    //     }
    //     if (responseStatusPayment) {
    //         setAlert(responseStatusPayment.message, responseStatusPayment.status)
    //         clearResponsePayment()
    //     }
    //     if (responseStatusCart) {
    //         setAlert(responseStatusCart.message, responseStatusCart.status)
    //         clearResponseCart()
    //     }
    //     if (responseStatusCredit) {
    //         setAlert(responseStatusCredit.message, responseStatusCredit.status)
    //         clearResponseCredit()
    //     }
    //     if (responseStatusOffline) {
    //         setAlert(responseStatusOffline.message, responseStatusOffline.status)
    //         clearResponseOffline()
    //     }
    // }, [
    //     responseStatusUser,
    //     responseStatusProduct,
    //     responseStatusAutoPlt,
    //     responseStatusAuth,
    //     responseStatusBuyNow,
    //     responseStatusPayment,
    //     responseStatusCart,
    //     responseStatusCredit,
    //     responseStatusOffline,
    // ])

    // useEffect(() => {
    //     if (responseStatusAuth) {
    //         if (responseStatusAuth.status === 'success') {
    //             handleRedirectInternal(history, '')
    //         }
    //     }
    // })

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    useEffect(() => {
        if (searchValue) {
            if (location.pathname == '/buscar/subastas') {
                handleRedirectInternal(history, 'buscar/subastas')
            } else if (location.pathname == '/buscar/ventas') {
                handleRedirectInternal(history, 'buscar/ventas')
            } else {
                handleRedirectInternal(history, `buscar/subastas`)
            }
        }
    }, [searchValue])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
    }

    const togglePopOverClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleDrawerSearch = (event) => {
        handleRedirectInternal(history, 'buscar/subastas')
        setAnchorEl(event.currentTarget)
        setState({ ...state, right: false })
    }

    const togglePopOverClose = () => {
        setAnchorEl(null)
    }

    const openSearchPopver = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title,p.desc_proc',
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        setSearchValue(getSearchBarObjet(searchText))
        setState({ ...state, top: false })
        if (location.pathname != '/buscar/subastas' && location.pathname != '/buscar/ventas') {
            handleRedirectInternal(history, 'buscar/subastas')
        }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/buscar/subastas' && location.pathname != '/buscar/ventas') {
                handleRedirectInternal(history, 'buscar/subastas')
            }
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            console.log('jiikkk', searchValue.filters.searchbar.value)
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: '',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                    active: {
                        value: '2',
                        type: 'notin',
                        field: 'b.active',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    const handleSearchAutocompleteTextChange = (item, reset) => {
        setSearchValue(getSearchBarObjet(item.state.query || ''))
        if (location.pathname != '/buscar/subastas' && location.pathname != '/buscar/ventas') {
            handleRedirectInternal(history, 'buscar/subastas')
        }
        return true
    }

    useEffect(() => {
        if (isAuthenticated) {
            getAllLotCount({
                additionalData: [
                    {
                        variable: 'p.id',
                        table: 'projects as p',
                        condition: `p.market_status IN ("unsold","closed") and p.user_id = ${user?.as_employees_id}  and p.auction = \"1\" and p.user_id = ${user?.as_employees_id} `,
                        alias: 'unsoldCount',
                    },
                ],
            })
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (all_lot_count && all_lot_count.from == 'lotcount') {
            setUnsoldLotCount(all_lot_count?.unsold_count)
        }
    }, [all_lot_count])

    return (
        <>
            {useCustomMediaQuery('(min-width: 1130px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <header className="mainHeader noPrint">
                        <div
                            className={`${
                                window.location.pathname == '/buscar/subastas'
                                    ? 'customContainer'
                                    : 'customContainer'
                            }`}
                        >
                            <div className="headerGridLayout">
                                <div className="headLt">
                                    <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                        <Autocomplete
                                            // openOnFocus={true}
                                            placeholder={t('search_top')}
                                            onSubmit={(stateData) => {
                                                stateData.setIsOpen(false)
                                                handleSearchAutocompleteTextChange(stateData)
                                            }}
                                            getSources={({ query }) => [
                                                {
                                                    sourceId: 'products',
                                                    onSelect({ setIsOpen, setQuery, event, item }) {
                                                        handleRedirectInternal(
                                                            history,
                                                            `productview/${item?.objectID}`,
                                                        )
                                                    },
                                                    getItems() {
                                                        let searchParams = {
                                                            query,
                                                            // filters: 'status:open',
                                                        }
                                                        if (
                                                            location.pathname == '/buscar/subastas'
                                                        ) {
                                                            searchParams['filters'] = 'auction:1'
                                                        } else if (
                                                            location.pathname == '/buscar/ventas'
                                                        ) {
                                                            searchParams['filters'] = 'auction:0'
                                                        }
                                                        return getAlgoliaResults({
                                                            searchClient,
                                                            queries: [
                                                                {
                                                                    indexName: APP_INDEX,
                                                                    ...searchParams,
                                                                },
                                                            ],
                                                        })
                                                    },

                                                    templates: {
                                                        // header() {
                                                        //     return 'Sugerencias'
                                                        // },

                                                        item({ item, components }) {
                                                            return (
                                                                <div>
                                                                    {item?.objectID ? (
                                                                        <div className="aa-ItemTitle">
                                                                            <components.Highlight
                                                                                hit={item}
                                                                                attribute="title"
                                                                            />
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            )
                                                        },
                                                    },
                                                },
                                            ]}
                                        />
                                        {/* <Button
                                            id="header_search"
                                            className="btn"
                                            onClick={handleSearchButtonClick}
                                        >
                                            <span className="material-icons">search</span>
                                        </Button>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder={t('search_top')}
                                            aria-label="Search"
                                            value={searchText}
                                            onChange={handleSearchTextChange}
                                            onKeyDown={handleSearchKeyDown}
                                            id="input_header"
                                        /> */}
                                    </div>
                                </div>
                                <Link to="/" className="ASlogo" id="subastas_logo">
                                    <img src={LOGO} alt={SITE_NAME} width="200" height="40" />
                                </Link>
                                <div className="menuRtOtr">
                                    <div className="headRt deskNav d-flex justify-content-end align-items-center">
                                        <ul className="d-flex justify-content-start align-items-center">
                                            {/* <li>
                                                <NavLink activeClassName="active" to="/about">
                                                    About
                                                </NavLink>
                                            </li> */}

                                            {isAuthenticated && (
                                                <>
                                                    <>
                                                        {/* <li>
                                                                <NavLink
                                                                    activeClassName="active"
                                                                    to="/post_project/auction_detail/new"
                                                                >
                                                                    Post an Auction
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink
                                                                    activeClassName="active"
                                                                    to="/post_project/create_lot/new"
                                                                >
                                                                    Post an Lot
                                                                </NavLink>
                                                            </li> */}
                                                        <li>
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/seller/auction/open"
                                                                id="header_myauction"
                                                            >
                                                                {t('my_auctions')}
                                                                {unSoldLotCount > 0 && (
                                                                    <span className="badgeCounthead">
                                                                        {unSoldLotCount}
                                                                    </span>
                                                                )}
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/seller/buynow/open"
                                                                id="header_myauction"
                                                            >
                                                                {t('my_buynow')}
                                                            </NavLink>
                                                        </li>
                                                    </>
                                                    {/* <>
                                                        <li>
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/buscar/subastas"
                                                                id="header_auction"
                                                            >
                                                                {t('auctions')}
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/buscar/ventas"
                                                                id="header_buynow"
                                                            >
                                                                {t('buynow')}
                                                            </NavLink>
                                                        </li>
                                                    </> */}
                                                </>
                                            )}

                                            {/* <li>
                                                <NavLink activeClassName="active" to="/contact">
                                                    Contact
                                                </NavLink>
                                            </li> */}
                                            <li>
                                                {isAuthenticated ? (
                                                    user?.admin ? (
                                                        ''
                                                    ) : (
                                                        <>
                                                            {/* <li className="headCart">
                                                                <Button
                                                                    onClick={() =>
                                                                        handleRedirectInternal(
                                                                            history,
                                                                            'watchlist/watchlist',
                                                                        )
                                                                    }
                                                                >
                                                                    <Badge color="primary">
                                                                        <span className="material-icons">
                                                                            favorite
                                                                        </span>
                                                                    </Badge>
                                                                </Button>
                                                            </li> */}
                                                            {/* <Button
                                                                aria-controls="simple-cart"
                                                                aria-haspopup="true"
                                                                onClick={handleCart}
                                                            >
                                                                <Badge
                                                                    badgeContent={
                                                                        count.buynowCount +
                                                                        count.auctionCount
                                                                    }
                                                                    color="primary"
                                                                >
                                                                    <span className="material-icons">
                                                                        shopping_cart
                                                                    </span>
                                                                </Badge>
                                                            </Button>
                                                            <Popover
                                                                anchorEl={anchorcart}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                id="simple-cart"
                                                                keepMounted
                                                                class={`headerDropMenu ${
                                                                    document.body.dir === 'rtl' &&
                                                                    'rtl'
                                                                }`}
                                                                open={Boolean(anchorcart)}
                                                                onClose={handleClose}
                                                            >
                                                                <ul
                                                                    className={`headerDropMenu ${
                                                                        document.body.dir ===
                                                                            'rtl' && 'rtl'
                                                                    }`}
                                                                >
                                                                    <ListItem
                                                                        button
                                                                        onClick={() =>
                                                                            handleClose(
                                                                                history,
                                                                                'buynowcart',
                                                                            )
                                                                        }
                                                                    >
                                                                        <Badge
                                                                            badgeContent={
                                                                                count.buynowCount
                                                                            }
                                                                            color="primary"
                                                                        >
                                                                            <span className="material-icons">
                                                                                shopping_bag
                                                                            </span>
                                                                        </Badge>
                                                                        {'Buy now cart'}
                                                                    </ListItem>
                                                                    <ListItem
                                                                        button
                                                                        onClick={() =>
                                                                            handleClose(
                                                                                history,
                                                                                'cart',
                                                                            )
                                                                        }
                                                                    >
                                                                        <Badge
                                                                            badgeContent={
                                                                                count.auctionCount
                                                                            }
                                                                            color="primary"
                                                                        >
                                                                            <span className="material-icons">
                                                                                gavel
                                                                            </span>
                                                                        </Badge>

                                                                        {'Auction cart'}
                                                                    </ListItem>
                                                                </ul>
                                                            </Popover> */}
                                                        </>
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </li>

                                            {/* {isAuthenticated ? null : (
                                                <li>{Object.values(mapData(switch_user))}</li>
                                            )} */}
                                        </ul>
                                        <ul className="d-flex justify-content-start align-items-center">
                                            {isAuthenticated ? (
                                                <>
                                                    <li className="headCart">
                                                        <Button
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    'watchlist/watchlist',
                                                                )
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                favorite
                                                            </span>
                                                        </Button>
                                                    </li>

                                                    {user?.admin ? (
                                                        <>
                                                            <li>
                                                                <Button
                                                                    aria-controls="simple-menu"
                                                                    aria-haspopup="true"
                                                                    onClick={handleMenu}
                                                                    id="header_user"
                                                                >
                                                                    <div className="userName">
                                                                        {user?.first_name}{' '}
                                                                        {user?.last_name}
                                                                    </div>
                                                                    <span className="material-icons ml-2">
                                                                        account_circle
                                                                    </span>
                                                                </Button>

                                                                <Popover
                                                                    id={'simple-menu'}
                                                                    open={Boolean(anchormenu)}
                                                                    anchorEl={anchormenu}
                                                                    onClose={handleClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    <ul
                                                                        className={`headerDropMenu ${
                                                                            document.body.dir ===
                                                                                'rtl' && 'rtl'
                                                                        }`}
                                                                    >
                                                                        <ListItem
                                                                            button
                                                                            id="header_profile"
                                                                            onClick={() => {
                                                                                setProfilePopup(
                                                                                    true,
                                                                                )

                                                                                handleClose(
                                                                                    history,
                                                                                    'cuenta/perfil',
                                                                                )
                                                                            }}
                                                                        >
                                                                            <span className="material-icons">
                                                                                person
                                                                            </span>
                                                                            {t('profile')}
                                                                        </ListItem>
                                                                        <ListItem
                                                                            button
                                                                            id="header_logout"
                                                                            onClick={() =>
                                                                                onLogOut()
                                                                            }
                                                                        >
                                                                            <span className="material-icons">
                                                                                power_settings_new
                                                                            </span>
                                                                            {t('logout')}
                                                                        </ListItem>
                                                                    </ul>
                                                                </Popover>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            <li>
                                                                <Button
                                                                    id="header_user_detail"
                                                                    aria-controls="simple-menu"
                                                                    aria-haspopup="true"
                                                                    onClick={handleMenu}
                                                                    label=""
                                                                >
                                                                    <div className="userName">
                                                                        {user?.first_name}{' '}
                                                                        {user?.last_name}
                                                                    </div>
                                                                    <span className="material-icons ml-2">
                                                                        account_circle
                                                                    </span>
                                                                    {/* <span className="userInitials small"></span> */}
                                                                </Button>

                                                                <Popover
                                                                    id={'simple-menu'}
                                                                    open={Boolean(anchormenu)}
                                                                    anchorEl={anchormenu}
                                                                    onClose={handleClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    <ul
                                                                        className={`headerDropMenu ${
                                                                            document.body.dir ===
                                                                                'rtl' && 'rtl'
                                                                        }`}
                                                                    >
                                                                        <ListItem
                                                                            button
                                                                            id="header_mybids"
                                                                            onClick={() =>
                                                                                handleClose(
                                                                                    history,
                                                                                    'auctions/all',
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="material-icons gavel">
                                                                                gavel
                                                                            </span>
                                                                            {t('my_bids')}
                                                                        </ListItem>
                                                                        {/* <ListItem
                                                                            button
                                                                            onClick={() =>
                                                                                handleClose(
                                                                                    history,
                                                                                    'wallet',
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="material-icons">
                                                                                account_balance_wallet
                                                                            </span>
                                                                            {'My Wallet'}
                                                                        </ListItem> */}

                                                                        <ListItem
                                                                            button
                                                                            id="header_profile_two"
                                                                            onClick={() =>
                                                                                handleClose(
                                                                                    history,
                                                                                    'cuenta/perfil',
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="material-icons">
                                                                                person
                                                                            </span>
                                                                            {t('profile')}
                                                                        </ListItem>
                                                                        <ListItem
                                                                            button
                                                                            id="header_logout_two"
                                                                            onClick={() =>
                                                                                onLogOut()
                                                                            }
                                                                        >
                                                                            <span className="material-icons">
                                                                                power_settings_new
                                                                            </span>
                                                                            {t('logout')}
                                                                        </ListItem>
                                                                    </ul>
                                                                </Popover>
                                                            </li>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <li>
                                                        <Button
                                                            onClick={() => setLoginModal(true)}
                                                            id="header_login"
                                                        >
                                                            {t('login')}
                                                        </Button>
                                                        /
                                                        <Button
                                                            id="header_register"
                                                            onClick={() => setSignupModal(true)}
                                                        >
                                                            {t('register')}
                                                        </Button>
                                                    </li>
                                                    {/* <li>
                                                        <NavLink
                                                            to="/registration"
                                                            activeClassName="active"
                                                            id="register"
                                                        >
                                                            register
                                                        </NavLink>
                                                    </li> */}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/">
                                <img src={LOGO} alt={SITE_NAME} width="130" height="25" />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li>
                                    {anchorEl ? null : (
                                        <Button
                                            id="header_user_search"
                                            onClick={togglePopOverClick}
                                        >
                                            <img
                                                src="/assets/images/magGlass.png"
                                                alt="searchIcon"
                                            />
                                            {/* <span className="material-icons">search</span> */}
                                        </Button>
                                    )}
                                </li>
                                {/* {isAuthenticated && (
                                    <li className="headCart">
                                        <Link to="/cart">
                                            <Badge color="primary">
                                                <span className="material-icons">
                                                    shopping_cart
                                                </span>
                                            </Badge>
                                        </Link>
                                    </li>
                                )} */}
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        id="header_menu"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <img src="/assets/images/burgerBar.png" alt="menubar" />
                                        {/* <span className="material-icons">menu</span> */}
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    {/* <React.Fragment>
                        <Drawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder={t('search_top')}
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button
                                    id="header_searchButton"
                                    className="btn"
                                    onClick={handleSearchButtonClick}
                                >
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </Drawer>
                    </React.Fragment> */}

                    <React.Fragment>
                        <Drawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img src={LOGO} alt={SITE_NAME} height="60" />
                                    </Link>
                                    <div className="ml-4 d-flex align-items-center">
                                        <Button
                                            id="header_user_search"
                                            className="searchIco"
                                            onClick={handleDrawerSearch}
                                        >
                                            <img
                                                src="/assets/images/magGlass.png"
                                                alt="searchIcon"
                                            />
                                            {/* <span className="material-icons">search</span> */}
                                        </Button>
                                        <Button
                                            id="header_clear"
                                            className="headDrawerClose"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">clear</span>
                                        </Button>
                                    </div>
                                </div>
                                <Divider />
                                {isAuthenticated && (
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to={'/cuenta/perfil'}
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">person</span>
                                            {t('my_account')}
                                        </NavLink>
                                    </ListItem>
                                )}
                                {isAuthenticated ? (
                                    user?.admin ? (
                                        <>
                                            <Divider />
                                            <ListItem button>
                                                <ListItemText>{t('Ventas')}</ListItemText>
                                                {/* <NavLink>
                                                    <span className="material-icons gavel">
                                                        storefront
                                                    </span>
                                                    {t('Compras')}
                                                </NavLink> */}
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/seller/lots/open"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Auctions
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/seller/auction/open"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    {t('my_auctions')}
                                                    {unSoldLotCount > 0 && (
                                                        <span className="mobileBadgeCount">
                                                            {unSoldLotCount}
                                                        </span>
                                                    )}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/seller/buynow/open"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    {t('Mis Ventas')}
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/seller/reports"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        description
                                                    </span>
                                                    {t('reports')}
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/payments/subscription"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    {t('Mis Suscripciones')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/invoiceseller/all"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {t('my_documents')}
                                                </NavLink>
                                            </ListItem>
                                            <Divider />
                                            <ListItem button>
                                                <ListItemText>{t('Compras')}</ListItemText>
                                                {/* <NavLink>
                                                    <span className="material-icons gavel">
                                                        storefront
                                                    </span>
                                                    {t('Compras')}
                                                </NavLink> */}
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/winning"
                                                    id="sidebar_all_bids"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons gavel">
                                                        gavel
                                                    </span>
                                                    {t('my_bids')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/alloffers"
                                                    id="sidebar_all_offers"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    {/* <img src="/assets/svg/priority_high.svg" alt="" /> */}
                                                    <span className="material-icons">
                                                        touch_app
                                                    </span>
                                                    {t('all_offers')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/documents/all"
                                                    id="sidebar_my_documents"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {t('my_documents')}
                                                </NavLink>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/all"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    {t('my_bids')}
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auction"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">live_tv</span>
                                                    {'Live auctions'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/search/auction"
                                                    onClose={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">category</span>
                                                    {'Products'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {'My purchases'}
                                                </NavLink>
                                            </ListItem> */}

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/alloffers"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        touch_app
                                                    </span>
                                                    {t('all_offers')}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/watchlist/watchlist"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    {t('my_favourites')}
                                                </NavLink>
                                            </ListItem>

                                            {/* <ListItem button>
                                                <NavLink activeClassName="active" to="/wallet">
                                                    <span className="material-icons">
                                                        account_balance_wallet
                                                    </span>
                                                    {'My wallet'}
                                                </NavLink>
                                            </ListItem> */}

                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/transactions"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        swap_vert
                                                    </span>
                                                    {'My transactions'}
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/documents/all"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {t('my_documents')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    {t('my_alerts')}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <ListItem button>
                                            <NavLink
                                                to="/"
                                                ActiveclassName="active"
                                                onClick={() => setLoginModal(true)}
                                                onClose={() => toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">login</span>
                                                {t('login')}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                to="/"
                                                ActiveclassName="active"
                                                onClick={() => setSignupModal(true)}
                                                onClose={() => toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">
                                                    app_registration
                                                </span>
                                                {t('register')}
                                            </NavLink>
                                        </ListItem>
                                    </>
                                )}
                                {/* <ul
                                    className="navRespLinks"
                                    onClick={(toggleDrawer('right', false), window.scrollTo(0, 0))}
                                >
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/auction">
                                            <span className="material-icons">live_tv</span>
                                            {'Live auctions'}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/search">
                                            <span className="material-icons">category</span>
                                            {'Products'}
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/account/profile'}
                                                >
                                                    <span className="material-icons">person</span>
                                                    {'Profile'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/all"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    {'My bids'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {'My purchases'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/watchlist/watchlist"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    {'Favorites'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/template">
                                                    <span className="material-icons">
                                                        table_chart
                                                    </span>
                                                    Templates
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/wallet">
                                                    <span className="material-icons">
                                                        account_balance_wallet
                                                    </span>
                                                    {'My wallet'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/transactions"
                                                >
                                                    <span className="material-icons">
                                                        swap_vert
                                                    </span>
                                                    {'My transactions'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/invoices/all"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {'My invoices'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    {'My saved searches'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/contact">
                                                    <span className="material-icons">
                                                        contact_support
                                                    </span>
                                                    {'Contact'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/about">
                                                    <span className="material-icons">info</span>
                                                    {'About'}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <NavLink to="/login" ActiveclassName="active">
                                                    <span className="material-icons">login</span>
                                                    {'Login'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    to="/registration"
                                                    activeClassName="active"
                                                >
                                                    <span className="material-icons">
                                                        app_registration
                                                    </span>
                                                    {t('register')}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}
                                    <ListItem button>
                                        <a href="tel:048897585">
                                            <span className="material-icons">call</span>
                                            {'Within uae'}:
                                            <span className="w-100 hnContact">04 889 7585</span>
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="tel: +97148897585">
                                            <span className="material-icons">call</span>
                                            {'International'}:
                                            <span className="w-100 hnContact">+971 4 889 7585</span>
                                        </a>
                                    </ListItem>
                                    {isAuthenticated && (
                                        <ListItem
                                            className="mt-3"
                                            button
                                            onClick={() => onLogOut()}
                                        >
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            {'Logout'}
                                        </ListItem>
                                    )}
                                </ul> */}
                                <ul className="navRespLinks mt-0">
                                    {/* <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/buscar/subastas"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">search</span>
                                            {t('subastas')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/buscar/ventas"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">gavel</span>
                                            {t('Ventas')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/vender-vehiculo"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">loyalty</span>
                                            {t('sell')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/subastar"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">pan_tool</span>
                                            {t('subastar')}
                                        </NavLink>
                                    </ListItem> */}

                                    {/* <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/preguntas-frequentes"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">quiz</span>
                                            {t('Preguntas frecuentes')}
                                        </NavLink>
                                    </ListItem> */}
                                    {/* <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/quienes-somos"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">info</span>
                                            {t('Nosotros')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/contactarnos"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">contact_support</span>
                                            {t('contact_us')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/quienes-somos"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">receipt_long</span>
                                            {t('Términos y condiciones')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/contactarnos"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">copyright</span>
                                            {t('Menciones legales')}
                                        </NavLink>
                                    </ListItem> */}

                                    {/* <ListItem button>
                                        <a href="tel:048897585">
                                            <span className="material-icons">call</span>{' '}
                                            {'Within uae'}:
                                            <span className="w-100 hnContact">04 889 7585</span>
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="tel: +97148897585">
                                            <span className="material-icons">call</span>
                                            {'International'}:
                                            <span className="w-100 hnContact">+971 4 889 7585</span>
                                        </a>
                                    </ListItem> */}
                                </ul>
                                {/* <Divider></Divider> */}
                                {/* {!isAuthenticated && (
                                    <ListItem button className="mt-4">
                                        {Object.values(mapData(switch_user))}
                                    </ListItem>
                                )} */}
                                {isAuthenticated && (
                                    <>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/watchlist/watchlist"
                                                id="sidebar_my_favourites"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">favorite</span>
                                                {t('my_favourites')}
                                            </NavLink>
                                        </ListItem>

                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/saved_search"
                                                id="sidebar_my_alerts"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">bookmark</span>
                                                {t('my_alerts')}
                                            </NavLink>
                                        </ListItem>
                                    </>
                                )}
                                {isAuthenticated && (
                                    <ListItem className="" button onClick={() => onLogOut()}>
                                        <span className="material-icons">power_settings_new</span>
                                        {t('logout')}
                                    </ListItem>
                                )}
                                {/* <ul className="navRespLinks wrappfixBtn">
                                    <ListItem button>
                                        <p className="m-0">© Subastaspro2023</p>
                                    </ListItem>
                                    <ListItem button>
                                        <img
                                            width="25"
                                            height="25"
                                            src="/assets/images/united-states.png"
                                        />
                                        <LanguageSwitch />
                                    </ListItem>
                                </ul> */}
                            </div>
                        </Drawer>
                    </React.Fragment>
                </>
            )}
            <BidHistory />
            <Login />
            <Registration />
            <Popover
                id={id}
                open={openSearchPopver}
                anchorEl={anchorEl}
                onClose={togglePopOverClose}
                className="searchPopOver respHeaderSearch"
                anchorReference="anchorPosition"
                anchorPosition={matches ? { top: 50 } : { top: 65 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="form-inline my-lg-0 headerSearchForm">
                    <Autocomplete
                        // openOnFocus={true}
                        placeholder={t('search_top')}
                        detachedMediaQuery={false}
                        onSubmit={(stateData) => {
                            togglePopOverClose()
                            stateData.setIsOpen(false)
                            handleSearchAutocompleteTextChange(stateData)
                        }}
                        getSources={({ query }) => [
                            {
                                sourceId: 'products',
                                onSelect({ setIsOpen, setQuery, event, item }) {
                                    togglePopOverClose()
                                    handleRedirectInternal(history, `productview/${item?.objectID}`)
                                },
                                getItems() {
                                    let searchParams = {
                                        query,
                                        // filters: 'status:open',
                                    }
                                    if (location.pathname == '/buscar/subastas') {
                                        searchParams['filters'] = 'auction:1'
                                    } else if (location.pathname == '/buscar/ventas') {
                                        searchParams['filters'] = 'auction:0'
                                    }
                                    return getAlgoliaResults({
                                        searchClient,
                                        queries: [
                                            {
                                                indexName: APP_INDEX,
                                                ...searchParams,
                                            },
                                        ],
                                    })
                                },

                                templates: {
                                    // header() {
                                    //     return 'Suggestions'
                                    // },
                                    item({ item, components }) {
                                        return (
                                            <>
                                                {item?.objectID ? (
                                                    <div className="aa-ItemTitle">
                                                        <components.Highlight
                                                            hit={item}
                                                            attribute="title"
                                                        />
                                                    </div>
                                                ) : null}
                                            </>
                                        )
                                    },
                                },
                            },
                        ]}
                    />
                    {/* <input
                        className="form-control"
                        type="search"
                        placeholder={t('search_top')}
                        aria-label="Search"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        onKeyDown={handleSearchKeyDown}
                    />
                    <Button
                        id="header_searchButton"
                        className="btn"
                        onClick={handleSearchButtonClick}
                    >
                        <span className="material-icons">search</span>
                    </Button> */}
                </div>
            </Popover>
            <CustomDialog
                title={t('add_saved_search')}
                open={savedSearchModel}
                function={changeEditDialogStatus}
                disableBackdropClick
                handleClose={closeFunction}
            >
                <div className="row">{mapData(searchBody, false, false, t)}</div>
                {/* <p className="forPass d-block text-center">
                    {t('Ya tienes una cuenta')}?
                    <Button id="login_signup" onClick={() => setLoginModal(true)} className="aPass">
                        {t('Identificarse')}
                    </Button>
                </p> */}
                <div className="actionWrapper">
                    <Button id="no_cancel" onClick={() => closeFunction()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        id="category_submit"
                        type="button"
                        onClick={() => formikSelection.handleSubmit()}
                        label="ACTIVAR"
                    />
                </div>
            </CustomDialog>
            <CustomDialog
                title={t('add_saved_search')}
                open={savedSearchPopup}
                function={changeEditDialogPopup}
                disableBackdropClick
                handleClose={closePopupFunction}
            >
                <div>
                    <p>
                        {' '}
                        Gracias por confiar en nosotros para encontrar la alerta de su vehículo.
                        Estamos a tu lado, en cada etapa de tu búsqueda.{' '}
                    </p>
                </div>
                <div className="actionWrapper">
                    {/* <Button id="no_cancel" onClick={() => closePopupFunction()}>
                        {t('cancel')}
                    </Button> */}
                    <PrimaryButton
                        id="category_submit"
                        type="button"
                        onClick={() => closePopupFunction()}
                        label={t('ok')}
                    />
                </div>
            </CustomDialog>
            <CustomDialog
                title={t('Please enter your phone number')}
                maxWidth={'xs'}
                open={phonemodelPopup}
                function={setPhonemodelPopup}
            >
                <form onSubmit={formikPhone.handleSubmit} autoComplete="nofill">
                    <div className="row">{Object.values(mapData(phoneNumber))}</div>
                    <div className="actionWrapper">
                        <Button id="cancel" onClick={() => setPhonemodelPopup(false)}>
                            {t('cancel')}
                        </Button>
                        <PrimaryButton
                            // onClick={formikPhone.handleSubmit}

                            type="submit"
                            label={t('submit')}
                        />
                    </div>
                </form>
            </CustomDialog>
            <PhoneValidationComponent
                profileUpdate={formikPhone.handleSubmit}
                phoneVerify={phoneVerify}
                formik={formikPhone}
                setPhoneVerify={setPhoneVerify}
                changePhoneVerify={changePhoneVerify}
            />
        </>
    )
}
export default Header
